// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-jsx": () => import("./../../../src/pages/business.jsx" /* webpackChunkName: "component---src-pages-business-jsx" */),
  "component---src-pages-company-jsx": () => import("./../../../src/pages/company.jsx" /* webpackChunkName: "component---src-pages-company-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-contact-thanks-jsx": () => import("./../../../src/pages/contact/thanks.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-microcms-news-news-id-jsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.jsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-jsx" */),
  "component---src-pages-philosophy-jsx": () => import("./../../../src/pages/philosophy.jsx" /* webpackChunkName: "component---src-pages-philosophy-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-recruit-jsx": () => import("./../../../src/pages/recruit.jsx" /* webpackChunkName: "component---src-pages-recruit-jsx" */),
  "component---src-templates-news-jsx": () => import("./../../../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */)
}

